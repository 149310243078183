<template>
  <layout>
    <template slot="header">
      {{ controlItem.node.name }}
    </template>
    <template>
      <div class="pb-2">
        <div
          v-for="item in listArguments(controlItem.node.params)"
          :key="item.nodeId"
          class="pt-2 pb-2"
        >
          {{ item.argument }}: {{ `${item.value}` }}
        </div>
        <div
          v-if="!listArguments(controlItem.node.params).length"
          class="pt-2 pb-2"
        >
          No arguments
        </div>
      </div>
      <v-divider v-if="linkedReports.length" />
      <v-list v-if="linkedReports.length" class="px-0 pb-0">
        <v-hover v-for="item in linkedReports" v-slot:default :key="item.id">
          <v-list-item class="px-0">
            <v-list-item-content class="pt-0 pb-0">
              <v-list-item-title class="text-wrap">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ convertDate(item.createdAt) }}, error: {{ item.error }},
                done: {{ item.done }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-hover>
      </v-list>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click="$emit('close')">close</v-btn>
      <v-btn
        color="primary"
        text
        @click="copyWithAlert(JSON.stringify(controlItem.node.params))"
      >
        copy
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { convertDate } from '@/utils';
import Layout from '@/components/popup/PopupLayoutDefault';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import isJsonString from '@/utils/validation/isJson';
import schemaGql from '@/modules/controls-history/api/historyList.graphql';
import { onMounted } from '@vue/composition-api';
import { useCopy } from '@/compositions/copy';

export default {
  name: 'PropertyInfo',
  components: {
    Layout
  },
  props: {
    controlItem: {
      type: Object,
      required: true
    },
    onSubmit: {
      type: Function,
      default: () => ({})
    }
  },
  setup(props) {
    const { copyWithAlert } = useCopy();
    const { result, load, loading } = useLazyQuery(
      schemaGql.getLinkedReport,
      {
        id: props.controlItem.node.id
      },
      {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
      }
    );

    const linkedReports = useResult(result, [], data => {
      return data.controlExecutions;
    });

    const listArguments = params => {
      let paramsInner = params;
      if (typeof params === 'string') {
        paramsInner = JSON.parse(params);
      }
      let paramsTransformed = [];
      let isArray = Array.isArray(paramsInner);
      if (isArray) {
        return paramsInner;
      } else {
        for (let prop in paramsInner) {
          paramsTransformed.push({
            argument: prop,
            isJson: isJsonString(paramsInner[prop]),
            value: paramsInner[prop]
          });
        }
      }
      return paramsTransformed;
    };

    onMounted(() => {
      load();
    });

    return {
      linkedReports,
      listArguments,
      convertDate,
      copyWithAlert
    };
  }
};
</script>
